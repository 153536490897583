import { Box, ChakraProvider, Flex } from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';
import styles from './App.module.css';
import { Navigator } from './navigator/Navigator';
import { Pages } from './pages/Pages';

export const App = () => {
  return (
    <div className={styles.app}>
      <RecoilRoot>
        <ChakraProvider>
          <Flex w='100%' h='100%' direction={{ base: 'column', lg: 'row-reverse' }}>
            <Box flex={{ base: 0, lg: 2 }}>
              <Navigator />
            </Box>
            <Box position='relative' flex={{ base: 1, lg: 5 }} overflow='hidden'>
              <Pages />
            </Box>
          </Flex>
        </ChakraProvider>
      </RecoilRoot>
    </div>
  );
};
