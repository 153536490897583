import { Box, Center, Flex, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import pitchAndPuttDog from '../../../assets/images/desktop/pitch-and-putt-dog.png';
import { domain, routeConfig } from '../../../config/route.config';
import { Description } from '../../shared/texts/Description';
import { Heading } from '../../shared/texts/Heading';

export const PitchAndPutt = () => {
  return (
    <>
      <Helmet>
        <title>{routeConfig.pitchAndPutt.name}</title>
        <link rel='canonical' href={domain + routeConfig.pitchAndPutt.path} />
      </Helmet>
      <Box w='100%' h='100%' overflow={{ base: 'scroll', lg: 'hidden' }}>
        <Box zIndex={-1} top={0} left={0} w='100%' h='100%' position='absolute' opacity={0.2} bg='#30552e' />
        <Flex h={{ lg: '100%' }} direction={{ base: 'column-reverse', lg: 'row' }}>
          <Center flex={1} paddingBottom={{ base: 14, lg: 0 }}>
            <Image w={{ base: '300px', lg: '70%' }} src={pitchAndPuttDog} alt='pitch-and-putt-dog' paddingTop={10} />
          </Center>
          <SimpleGrid
            flex={1}
            columns={1}
            spacing={8}
            padding={{ base: 6, md: 8, lg: 10 }}
            overflow={{ base: 'auto', lg: 'scroll' }}>
            <Box>
              <Text fontSize='3xl' fontFamily='PoppinsRegular'>
                What is?
              </Text>
              <Heading>PITCH & PUTT</Heading>
              <Text fontSize='3xl' fontFamily='OctinCollegeBold'>
                (18 HOLES)
              </Text>
            </Box>
            <Description>
              Pitch and putt is an amateur sport very similar to, and derived from golf, where the hole length is
              typically up to 90 metres and just 2-3 clubs are typically used. Invented in Ireland in 1929, and
              developed through the 1940s,
            </Description>
            <Description>It is a great game for all golfers, beginners, family and social.</Description>
            <Description>Enjoy!</Description>
            <Description>
              <strong>Pitch & Putt Price</strong>
              <br />
              -9 Holes
              <br />
              Adult (17yr +): $15
              <br />
              Child (5yr - 16yr): $12
              <br />
              Toddler (under 4yr): $5
              <br />
              Senior (65yr +): $12
            </Description>
            <Description>
              -18 holes
              <br />
              Adult (17yr +): $20
              <br />
              Child (5yr - 16yr): $17
              <br />
              Toddler (under 4yr): $5
              <br />
              Senior (65yr +): $17
            </Description>
            <Description>
              Family Pass
              <br />
              2 adults, 2 child = $45 (9holes) / $60 (18holes)
              <br />
              2 adults, 1 child = $35 (9holes) / $50 (18holes)
              <br />1 adults, 2 child = $33 (9holes) / $45 (18holes)
            </Description>
          </SimpleGrid>
        </Flex>
      </Box>
    </>
  );
};
