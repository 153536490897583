import { Box, Hide, Image, Link, Show } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import desktopCafeInfo01 from '../../../assets/images/desktop/cafe-info-01.jpg';
import desktopCafeInfo02 from '../../../assets/images/desktop/cafe-info-02.jpg';
import mobileCafeInfo01 from '../../../assets/images/mobile/cafe-info-01.jpg';
import mobileCafeInfo02 from '../../../assets/images/mobile/cafe-info-02.jpg';
import { domain, routeConfig } from '../../../config/route.config';

export const CafeMenu = () => {
  return (
    <>
      <Helmet>
        <title>{routeConfig.cafeMenu.name}</title>
        <link rel='canonical' href={domain + routeConfig.cafeMenu.path} />
      </Helmet>
      <Box w='100%' h='100%' overflow='scroll'>
        <Box zIndex={-1} top={0} left={0} w='100%' h='100%' position='absolute' opacity={0.2} bg='#30552e' />
        <Show above='lg'>
          <Link href={desktopCafeInfo01} target='_blank'>
            <Image src={desktopCafeInfo01} alt='cafe-menu-info-01' />
          </Link>
          <Link href={desktopCafeInfo02} target='_blank'>
            <Image src={desktopCafeInfo02} alt='cafe-menu-info-02' />
          </Link>
        </Show>
        <Hide above='lg'>
          <Link href={mobileCafeInfo01} target='_blank'>
            <Image src={mobileCafeInfo01} alt='golf-price-info-01' />
          </Link>
          <Link href={mobileCafeInfo02} target='_blank'>
            <Image src={mobileCafeInfo02} alt='golf-price-info-02' />
          </Link>
        </Hide>
      </Box>
    </>
  );
};
