import { Box, Hide, Image, Link, Show } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import desktopGolfPriceInfo from '../../../assets/images/desktop/golf-price-info.jpg';
import mobileGolfPriceInfo from '../../../assets/images/mobile/golf-price-info.jpg';
import { domain, routeConfig } from '../../../config/route.config';

export const GolfPrice = () => {
  return (
    <>
      <Helmet>
        <title>{routeConfig.golfPrice.name}</title>
        <link rel='canonical' href={domain + routeConfig.golfPrice.path} />
      </Helmet>
      <Box w='100%' h='100%' overflow='scroll'>
        <Show above='lg'>
          <Link href={desktopGolfPriceInfo} target='_blank'>
            <Image src={desktopGolfPriceInfo} alt='golf-price-info' />
          </Link>
        </Show>
        <Hide above='lg'>
          <Link href={mobileGolfPriceInfo} target='_blank'>
            <Image src={mobileGolfPriceInfo} alt='golf-price-info' />
          </Link>
        </Hide>
      </Box>
    </>
  );
};
