import { Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

type HeadingProps = {
  children: ReactNode;
};

export const Heading = ({ children }: HeadingProps) => {
  return (
    <Text fontSize='5xl' fontFamily='OctinCollegeBold'>
      {children}
    </Text>
  );
};
