import { Box, Text } from '@chakra-ui/react';

type TitleBackdropProps = {
  zIndex: number;
  color: string;
  opacity: number;
};

export const TitleBackdrop = ({ zIndex, color, opacity }: TitleBackdropProps) => {
  return (
    <Box
      zIndex={zIndex}
      top={0}
      left={0}
      padding='1rem 1.5rem'
      position='absolute'
      color={color}
      fontFamily='OctinCollegeRegular'
      opacity={opacity}>
      <Text fontSize={{ base: 80, sm: 100, md: 160, lg: 200 }} lineHeight={1} userSelect='none'>
        CALLUM
      </Text>
      <Text fontSize={{ base: 80, sm: 100, md: 160, lg: 200 }} lineHeight={1} userSelect='none'>
        BRAE
      </Text>
      <Text padding={2} fontSize={{ base: 30, sm: 40, md: 60, lg: 80 }} lineHeight={1} userSelect='none'>
        FAMILY GOLF & CAFE
      </Text>
    </Box>
  );
};
