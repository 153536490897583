import { IconButton } from '@chakra-ui/react';
import { IoHomeOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { routeConfig } from '../../../config/route.config';

export const HomeButton = () => {
  return (
    <Link to={routeConfig.root.path}>
      <IconButton
        bg='#30552e'
        color='white'
        aria-label='Home'
        boxSize={14}
        opacity={0.6}
        borderRadius={100}
        _hover={{ transform: 'rotate(360deg)', transition: 'transform 0.7s ease-in-out' }}
        icon={<IoHomeOutline size={30} />}
      />
    </Link>
  );
};
