import { Box, Flex, Icon, Link, SimpleGrid, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { AiOutlineMail } from 'react-icons/ai';
import { CgInstagram } from 'react-icons/cg';
import { FaFacebookSquare } from 'react-icons/fa';
import { FiPhone } from 'react-icons/fi';
import { GrMap } from 'react-icons/gr';
import { domain, routeConfig } from '../../../config/route.config';
import { Heading } from '../../shared/texts/Heading';

export const EnquiryForm = () => {
  return (
    <>
      <Helmet>
        <title>{routeConfig.enquiryForm.name}</title>
        <link rel='canonical' href={domain + routeConfig.enquiryForm.path} />
      </Helmet>
      <Box w='100%' h='100%' overflow={{ base: 'scroll', lg: 'hidden' }}>
        <Box zIndex={-1} top={0} left={0} w='100%' h='100%' position='absolute' opacity={0.2} bg='#30552e' />
        <Flex h={{ lg: '100%' }} direction={{ base: 'column', lg: 'row' }}>
          <SimpleGrid
            flex={1}
            columns={1}
            spacing={8}
            padding={{ base: 6, md: 8, lg: 10 }}
            overflow={{ base: 'auto', lg: 'scroll' }}>
            <Box>
              <Heading>CONTACT</Heading>
              {/* <Heading>ENQUIRY FORM</Heading> */}
            </Box>
            <Box>
              <Link href='tel:+6478551234'>
                <Flex align='center'>
                  <Icon as={FiPhone} boxSize={8} marginRight={4} />
                  <Text fontSize='4xl' fontFamily='OctinCollegeBold'>
                    Phone
                  </Text>
                </Flex>
                <Text fontSize='3xl' fontFamily='PoppinsRegular'>
                  07 855 1234
                </Text>
              </Link>
            </Box>
            <Box>
              <Link href='mailto:hello@callumbrae.com'>
                <Flex align='center'>
                  <Icon as={AiOutlineMail} boxSize={8} marginRight={4} />
                  <Text fontSize='4xl' fontFamily='OctinCollegeBold'>
                    Email
                  </Text>
                </Flex>
                <Text fontSize='3xl' fontFamily='PoppinsRegular'>
                  hello@callumbrae.com
                </Text>
              </Link>
            </Box>
            <Box>
              <Link href='https://goo.gl/maps/2yEEZ1WjfSmA5oTz9' target='_blank'>
                <Flex align='center'>
                  <Icon as={GrMap} boxSize={8} marginRight={4} />
                  <Text fontSize='4xl' fontFamily='OctinCollegeBold'>
                    Address
                  </Text>
                </Flex>
                <Text fontSize='2xl' fontFamily='PoppinsRegular'>
                  Callumbrae Family Golf & Cafe
                </Text>
                <Text fontSize='2xl' fontFamily='PoppinsRegular'>
                  25 Callum Brae Dr.
                </Text>
                <Text fontSize='2xl' fontFamily='PoppinsRegular'>
                  Rototuna, Hamilton
                </Text>
              </Link>
            </Box>
            <Box>
              <Link href='https://www.facebook.com/callumbrae25' target='_blank'>
                <Icon as={FaFacebookSquare} boxSize={14} marginRight={4} />
              </Link>
              <Link href='https://www.instagram.com/callumbrae' target='_blank'>
                <Icon as={CgInstagram} boxSize={14} />
              </Link>
            </Box>
          </SimpleGrid>
          <SimpleGrid
            flex={1}
            columns={1}
            spacing={8}
            padding={{ base: 6, md: 8, lg: 10 }}
            overflow={{ base: 'auto', lg: 'scroll' }}></SimpleGrid>
        </Flex>
      </Box>
    </>
  );
};
