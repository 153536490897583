import { Box, Center, Image, Link, SimpleGrid, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Loadable, useRecoilValueLoadable } from 'recoil';
import { domain, routeConfig } from '../../../config/route.config';
import { fetchInstagramMedias } from '../../../store/instagram/instagram.selectors';
import { InstagramMedia, InstagramMediaEnum } from '../../../store/instagram/instagram.types';

export const Photos = () => {
  const [instagramMedias, setInstagramMedias] = useState<InstagramMedia[]>([]);
  const instagramMediasLoadable: Loadable<InstagramMedia[]> = useRecoilValueLoadable(fetchInstagramMedias);

  useEffect(() => {
    if (instagramMediasLoadable.state === 'hasValue') {
      setInstagramMedias(instagramMediasLoadable.contents);
    }
  }, [instagramMediasLoadable]);

  return (
    <>
      <Helmet>
        <title>{routeConfig.photos.name}</title>
        <link rel='canonical' href={domain + routeConfig.photos.path} />
      </Helmet>
      <Box w='100%' h='100%' padding={4} overflow='scroll'>
        {instagramMedias.length ? (
          <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={4}>
            {instagramMedias.map((instagramMedia: InstagramMedia) => {
              const src: string | undefined =
                instagramMedia.media_type === InstagramMediaEnum.IMAGE
                  ? instagramMedia.media_url
                  : instagramMedia.thumbnail_url;
              return (
                <Box key={instagramMedia.id}>
                  <Link href={instagramMedia.permalink} target='_blank'>
                    <Image src={src} alt={instagramMedia.id} />
                  </Link>
                </Box>
              );
            })}
          </SimpleGrid>
        ) : (
          <Center h='100%'>
            <Spinner size='xl' speed='0.65s' color='#0495ea' emptyColor='gray.200' thickness='4px' />
          </Center>
        )}
      </Box>
    </>
  );
};
