import { Box, Center, Flex, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import hoursDogs from '../../../assets/images/desktop/hours-dogs.png';
import { domain, routeConfig } from '../../../config/route.config';

export const Hours = () => {
  return (
    <>
      <Helmet>
        <title>{routeConfig.hours.name}</title>
        <link rel='canonical' href={domain + routeConfig.hours.path} />
      </Helmet>
      <Box w='100%' h='100%' overflow={{ base: 'scroll', lg: 'hidden' }}>
        <Box zIndex={-1} top={0} left={0} w='100%' h='100%' position='absolute' opacity={0.2} bg='#30552e' />
        <Flex h={{ lg: '100%' }} direction={{ base: 'column-reverse', lg: 'row' }}>
          <Center flex={1} paddingBottom={{ base: 14, lg: 0 }}>
            <Flex align='center' direction='column'>
              <Image w={{ base: '300px', lg: '80%' }} src={hoursDogs} alt='hours-dogs' paddingTop={10} />
            </Flex>
          </Center>
          <Center>
            <SimpleGrid
              flex={1}
              columns={1}
              spacing={8}
              padding={{ base: 6, md: 8, lg: 10 }}
              overflow={{ base: 'auto', lg: 'scroll' }}>
              <Box>
                <Text fontSize='4xl' fontFamily='PoppinsRegular' fontWeight='bold'>
                  -Golf hours
                </Text>
                <Text fontSize='3xl' fontFamily='PoppinsRegular'>
                  We are open 365days
                </Text>
                <Text fontSize='3xl' fontFamily='PoppinsRegular'>
                  (Weather permitted)
                </Text>
                <Text fontSize='5xl' fontFamily='OctinCollegeBold'>
                  9AM - TILL DUSK
                </Text>
              </Box>
              <Box>
                <Text fontSize='4xl' fontFamily='PoppinsRegular' fontWeight='bold'>
                  -Cafe hours
                </Text>
                <Text fontSize='3xl' fontFamily='PoppinsRegular'>
                  Various times depend on seasons
                </Text>
                <Text fontSize='3xl' fontFamily='PoppinsRegular'>
                  Please enquiry us <a href='tel:+6478551234'>07) 855 1234</a>
                </Text>
              </Box>
            </SimpleGrid>
          </Center>
        </Flex>
      </Box>
    </>
  );
};
