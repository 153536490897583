import { RouteConfig } from './route.config.types';

export const domain: string = window.location.hostname;

export const routeConfig: RouteConfig = {
  root: {
    path: '/',
    name: 'CALLUM BRAE | Family Golf & Cafe',
  },
  aboutUs: {
    path: '/about-us',
    name: 'About Us',
  },
  miniGolf: {
    path: '/mini-golf',
    name: 'Mini Golf',
  },
  pitchAndPutt: {
    path: '/pitch-and-putt',
    name: 'Pitch & Putt',
  },
  cafeMenu: {
    path: '/cafe-menu',
    name: 'Cafe Menu',
  },
  golfPrice: {
    path: '/golf-price',
    name: 'Golf Price',
  },
  hours: {
    path: '/hours',
    name: 'Hours',
  },
  photos: {
    path: '/photos',
    name: 'Photos',
  },
  enquiryForm: {
    // path: '/enquiry-form',
    // name: 'Enquiry Form',
    path: '/contact',
    name: 'Contact',
  },
};
