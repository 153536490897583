import { Box, Flex, Icon, Link, SimpleGrid, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { FiPhone } from 'react-icons/fi';
import { GrMap } from 'react-icons/gr';
import { domain, routeConfig } from '../../../config/route.config';
import { HomeButton } from '../../shared/buttons/HomeButton';
import { Description } from '../../shared/texts/Description';
import { Heading } from '../../shared/texts/Heading';

export const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>{routeConfig.aboutUs.name}</title>
        <link rel='canonical' href={domain + routeConfig.aboutUs.path} />
      </Helmet>
      <Box w='100%' h='100%' padding={{ base: 6, md: 8, lg: 10 }} overflow='scroll'>
        <Box zIndex={-1} top={0} left={0} w='100%' h='100%' position='absolute' opacity={0.2} bg='#30552e' />
        <SimpleGrid columns={1} spacing={8} w={{ base: '100%', lg: '65%' }} minW={{ lg: 600 }}>
          <Heading>ABOUT US</Heading>
          <Box>
            <Box top={0} right={0} padding={{ base: 4, md: 6, lg: 8 }} position='absolute'>
              <HomeButton />
            </Box>
            <Link href='https://goo.gl/maps/2yEEZ1WjfSmA5oTz9' target='_blank'>
              <Flex align='center'>
                <Icon as={GrMap} boxSize={8} marginRight={4} />
                <Text fontSize='4xl' fontFamily='OctinCollegeBold'>
                  Address
                </Text>
              </Flex>
              <Text fontSize='2xl' fontFamily='PoppinsRegular'>
                Callumbrae Family Golf & Cafe
              </Text>
              <Text fontSize='2xl' fontFamily='PoppinsRegular'>
                25 Callum Brae Dr.
              </Text>
              <Text fontSize='2xl' fontFamily='PoppinsRegular'>
                Rototuna, Hamilton
              </Text>
            </Link>
          </Box>
          <Box>
            <Link href='tel:+6478551234'>
              <Flex align='center'>
                <Icon as={FiPhone} boxSize={8} marginRight={4} />
                <Text fontSize='4xl' fontFamily='OctinCollegeBold'>
                  Phone
                </Text>
              </Flex>
              <Text fontSize='3xl' fontFamily='PoppinsRegular'>
                07 855 1234
              </Text>
            </Link>
          </Box>
          <Description>
            Since opening our doors to the public in 1998, CALLUMBRAE has become a must-visit destination for people all
            over the Hamilton area. Right in the middle of Rototuna housing, It is like an oasis. A huge expanse of
            green, dedicated to family play.
          </Description>
          <Description>
            We offer our best entertainment services for everyone to enjoy. Whether you’re planning a day trip with your
            family, or are coming with friends, We give visitors of all ages the chance to enjoy themselves in a
            fun-filled environment.
          </Description>
          <Description>Swing by for a visit today.</Description>
        </SimpleGrid>
      </Box>
    </>
  );
};
