import { RecoilValueReadOnly, selector } from 'recoil';
import { getInstagramMedias } from './instagram.client';
import { InstagramMedia } from './instagram.types';

export const fetchInstagramMedias: RecoilValueReadOnly<InstagramMedia[]> = selector({
  key: 'fetch-instagram-medias',
  get: async (): Promise<InstagramMedia[]> => {
    return await getInstagramMedias();
  },
});
