import { Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

type DescriptionProps = {
  children: ReactNode;
};

export const Description = ({ children }: DescriptionProps) => {
  return (
    <Text fontSize={{ base: '1.2rem', md: '1.5rem' }} fontFamily='PoppinsRegular'>
      {children}
    </Text>
  );
};
