import { Box, Center, Flex, Image, SimpleGrid, Text } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import miniGolfDog from '../../../assets/images/desktop/mini-golf-dog.png';
import { domain, routeConfig } from '../../../config/route.config';
import { Description } from '../../shared/texts/Description';
import { Heading } from '../../shared/texts/Heading';

export const MiniGolf = () => {
  return (
    <>
      <Helmet>
        <title>{routeConfig.miniGolf.name}</title>
        <link rel='canonical' href={domain + routeConfig.miniGolf.path} />
      </Helmet>
      <Box w='100%' h='100%' overflow={{ base: 'scroll', lg: 'hidden' }}>
        <Box zIndex={-1} top={0} left={0} w='100%' h='100%' position='absolute' opacity={0.2} bg='#30552e' />
        <Flex h={{ lg: '100%' }} direction={{ base: 'column-reverse', lg: 'row' }}>
          <Center flex={1} paddingBottom={{ base: 14, lg: 0 }}>
            <Image w={{ base: '300px', lg: '70%' }} src={miniGolfDog} alt='mini-golf-dog' paddingTop={10} />
          </Center>
          <SimpleGrid
            flex={1}
            columns={1}
            spacing={8}
            padding={{ base: 6, md: 8, lg: 10 }}
            overflow={{ base: 'auto', lg: 'scroll' }}>
            <Box>
              <Text fontSize='3xl' fontFamily='PoppinsRegular'>
                What is?
              </Text>
              <Heading>MINI GOLF</Heading>
              <Text fontSize='3xl' fontFamily='OctinCollegeBold'>
                (36 HOLES)
              </Text>
            </Box>
            <Description>
              Miniature golf, also known as minigolf, mini-putt, crazy golf, or putt-putt, is an offshoot of the sport
              of golf focusing solely on the putting aspect of its parent game. It is played on courses consisting of a
              series of holes similar to its parent, but characterized by their short length
            </Description>
            <Description>It is for all ages, any level, from toddler to senior.</Description>
            <Description>
              <strong>Mini Golf Price</strong>
              <br />
              -18 Holes
              <br />
              Adult (17yr +): $15
              <br />
              Child (5yr - 16yr): $12
              <br />
              Toddler (under 4yr): $5
              <br />
              Senior (65yr +): $12
            </Description>
            <Description>
              -36 holes
              <br />
              Adult (17yr +): $20
              <br />
              Child (5yr - 16yr): $17
              <br />
              Toddler (under 4yr): $5
              <br />
              Senior (65yr +): $17
            </Description>
            <Description>
              Family Pass
              <br />
              2 adults, 2 child = $45 (18holes) / $60 (36holes)
              <br />
              2 adults, 1 child = $35 (18holes) / $50 (36holes)
              <br />1 adults, 2 child = $33 (18holes) / $45 (36holes)
            </Description>
          </SimpleGrid>
        </Flex>
      </Box>
    </>
  );
};
