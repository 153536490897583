export enum InstagramMediaEnum {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export type InstagramMedia = {
  id: string;
  permalink: string;
  media_type: InstagramMediaEnum;
  media_url: string;
  thumbnail_url?: string;
  timestamp: string;
};
